<template>
  <div>
    <div class="d-flex justify-center align-items-center font-weight-bolder">
      <p style="width: 2.5%" />
      <p
        style="width: 4.5%"
        class=" mb-0 ml-1"
      >
        State
      </p>
      <p
        style="width: 14%"
        class="mb-0"
      >
        Id
      </p>
      <p
        style="width: 37%"
        class="mb-0"
      >
        Name
      </p>
      <p
        v-if="selectedReceiving"
        class="mb-0"
      >
        Received
      </p>
    </div>
    <div class="tableContent mb-1">
      <div
        v-for="(item) in tableBody"
        :key="item.id"
        style="padding: 0 7px;"
        :class="`${selectedReceiving && has(item,'received_at') && item.received_at ? 'active-items' : ''}`"
      >
        <div
          :id="`collapse-item-${item.id}`"
        >
          <div class="d-flex align-items-center containersItem">
            <div style="width: 1.5%">
              <feather-icon
                icon="LStockEyeIcon"
                class="cursor-pointer"
                size="32"
                @click="goToPage(item,$event)"
              />
            </div>
            <div
              class="d-flex align-items-center cursor-pointer"
              @click="toggle(item.id)"
            >
              <feather-icon
                :id="`feather-chevron-${item.id}`"
                icon="LArrowDownIcon"
                size="10"
                class="featherChevron ml-1 mr-1"
                style="transform: rotate(-90deg)"
              />
              <div>
                <state-of-asset
                  :data="item"
                  :with-label="false"
                  :styles="{ color: '#00B139', width: '14px', height: '14px' }"
                />
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between"
              style="width: 13.5%; gap: 12px"
            >
              <div>{{ getValueFromGivenObjectByKey(item, 'asset_id') }}</div>
              <div
                v-show="item.type_id === 3"
                class="icon-wrapper active"
              >
                <feather-icon
                  icon="LBoxIcon"
                  size="14"
                />
              </div>
            </div>
            <div style="width: 38.5%;">
              {{ getValueFromGivenObjectByKey(item, 'product.name') }}
            </div>
            <div
              v-if="selectedReceiving && has(item,'received_at')"
              class="cursor-pointer"
              @click="handleCheckBySerialNumber(item)"
            >

              <feather-icon
                v-if="item.received_at"
                icon="LApproved"
                size="14"
              />
              <feather-icon
                v-else
                icon="LMinusIconBold"
                size="14"
              />
            </div>
            <div
              v-show="selectedReceiving"
              style="margin-left: auto !important;"
            >
              <button-dashed
                class="mr-0"
                style="background-color: #D9D9D9 !important; padding: 4px 6px"
                @click="showReportModal(item.id)"
              >
                <feather-icon
                  icon="LWarningGreyIcon"
                  size="14"
                  class="cursor-pointer"
                />
              </button-dashed>
            </div>
          </div>
          <report-modal
            :order-item="item"
            @reportedAsset="reportedAsset"
          />
        </div>
        <div
          :id="`collapse-${item.id}`"
          class="accordion__panel rowAssetItems"
        >
          <div
            v-for="(product, index) in item.children"
            :key="index"
            class="rowContainer"
          >
            <div class="mainInfo">
              <div style="width: 1.6%">
                <feather-icon
                  icon="LStockEyeIcon"
                  class="cursor-pointer"
                  size="32"
                  @click="goToPage(product,$event)"
                />
              </div>
              <div
                style="margin-left: 1.5%"
              >
                <state-of-asset
                  :data="product"
                  :with-label="false"
                  :styles="{ color: '#00B139', width: '14px', height: '14px' }"
                />
              </div>
              <div
                style="width: 13.8%"
              >
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="gap: 12px"
                >
                  <div>{{ getValueFromGivenObjectByKey(product, 'asset_id') }}</div>
                  <div
                    v-show="item.type_id === 3"
                    class="icon-wrapper active"
                  >
                    <feather-icon
                      icon="LBoxIcon"
                      size="14"
                    />
                  </div>
                </div>
              </div>
              <p>
                {{ getValueFromGivenObjectByKey(product, 'product.name') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="font-weight-bolder mb-0 text-right">
      Items {{ tableBody.length }}
    </p>
  </div>
</template>
<script>

import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import * as _ from 'lodash'
import has from 'lodash/has'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import ReportModal from '@/views/main/warehouse/view/dispatch/components/rental-items/ReportModal.vue'

export default {
  name: 'ByContainer',
  components: { ReportModal, ButtonDashed, StateOfAsset },
  props: {
    orderItem: {
      type: Array,
      default: () => [],
    },
    selectedReceiving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableBody() {
      return _.reduce(this.orderItem, (acc, item) => {
        if (!item.parent_id) {
          acc.push({
            ...item,
            children: _.filter(this.orderItem, { parent_id: item.id }),
          })
        }
        return acc
      }, [])
    },
  },
  methods: {
    handleCheckBySerialNumber(item) {
      this.$emit('getValue', item?.inventory_item?.serial_number || item?.product?.sku)
    },
    toggle(elId) {
      const getToggleItemEl = document.getElementById(`collapse-item-${elId}`)
      const attr = getToggleItemEl.getAttribute('visible')
      const getCollapseEl = document.getElementById(`collapse-${elId}`)
      const icon = document.getElementById(`feather-chevron-${elId}`)
      if (!attr || attr === 'false') {
        getToggleItemEl.setAttribute('visible', 'true')
        getCollapseEl.classList.add('accordion__panel-active')
        icon.classList.add('when-opened')
      } else {
        getToggleItemEl.setAttribute('visible', 'false')
        getCollapseEl.classList.remove('accordion__panel-active')
        icon.classList.remove('when-opened')
      }

      getCollapseEl.style.setProperty('--height', `${getCollapseEl.scrollHeight}px`)
    },
    showReportModal(item) {
      this.$bvModal.show(`report-warning-modal-${item}`)
    },
    reportedAsset() {
      this.$emit('reportedAsset')
    },
    goToPage(item, e) {
      if (!item?.product_id || !item?.asset_id) return
      e.stopPropagation()
      this.gotoNewPage(`/inventory/inventory-view/${item?.product_id}/update/${item.asset_id}`, e, '_blank')
    },
  },
  setup() {
    return {
      getValueFromGivenObjectByKey,
      has,
    }
  },

}

</script>
<style scoped lang="scss">

.tableContent {
  overflow: hidden;
  border: 1px solid #DDE3E7;
  border-radius: 5px;
  width: 100%;
  height: 100%;

  .active-items{
    background: #00B13926;
  }
}

.containersItem{
  padding: 7px 0;
  gap: 12px;
}

.featherChevron{
  transition: ease 0.25s;
}

.when-opened{
  transform: rotate(0deg) !important;
}

.accordion__panel{
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  padding: 0 5px;
  transition: visibility 300ms, max-height 400ms;
  transition-timing-function: cubic-bezier(0, 1.1, 2, 10);
}

.accordion__panel-active {
  max-height: var(--height, 0);
  visibility: visible;
}

.rowAssetItems{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  &:last-child{
    margin-bottom: 12px;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  background: rgba(0, 177, 57, 0.15);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  padding: 7px 12px;

  & p {
    margin: 0;
  }
}

.icon-wrapper {
  padding: 5px 8px;
  background: rgba(243, 243, 243, 1);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  cursor: pointer;
  width: min-content;
  & > .feather {
    width: unset;
    height: unset;
  }

  &.active {
    background: #646464;

    svg {
      color: white;
    }
  }
}
</style>
