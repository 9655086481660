<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order') }} {{ orderType }}-{{ id }}
      </h3>
    </portal>
    <skeleton v-if="loading" />
    <signature-form v-else />
  </div>
</template>
<script>
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import SignatureForm from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureForm.vue'
import config from '@/views/main/warehouse/view/dispatch/dispatch-signature/signatureConfig'
import store from '@/store'
import dispatchModule from '@/store/main/warehouse/dispatch'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'DispatchSignature',
  components: { Skeleton, SignatureForm },

  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
    }
  },
  created() {
    this.refetch()
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getFulFillment`, { id: this.$route.params.id }).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_DATA`, data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, dispatchModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
    }
  },
}
</script>
