import isString from 'lodash/isString'

export default function config() {
  const MODULE_NAME = 'warehouse'

  const outBoundQuoteItems = [
    {
      icon: 'LPickIcon',
      title: 'Pick',
      iconSize: '24',
      // stateId: 5,
    },
    {
      icon: 'LBoxIcon',
      title: 'Pack',
      iconSize: '24',
      // stateId: 6,
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '24',
      // stateId: 7,
    },
  ]

  const inBoundQuoteItems = [
    {
      icon: 'LWarehouseIcon',
      title: 'Receive',
      iconSize: '24',
      // stateId: 8,
    },
    {
      icon: 'LUnPuckIcon',
      title: 'Unpack',
      iconSize: '24',
      // stateId: 9,
    },
    {
      icon: 'LBoxSentIcon',
      title: 'Put Back',
      iconSize: '24',
      // stateId: 10,
    },
  ]

  const quoteStatusItemsMerge = (states = {}, isOrderInBound) => {
    const items = []
    const quoteItems = isOrderInBound ? inBoundQuoteItems : outBoundQuoteItems
    const statesItems = Array.isArray(states) ? states : Object.entries(states).map(([key, value]) => ({ label: value, value: Number(key) }))
    quoteItems.forEach(item => {
      statesItems.forEach((state, index) => {
        if (isString(state) && item.title.toLocaleLowerCase() === state.toLocaleLowerCase()) {
          items.push({ ...item, stateId: index })
        } else if (!isString(state) && item.title.toLocaleLowerCase() === state.label.toLocaleLowerCase()) {
          items.push({ ...item, stateId: state.value })
        }
      })
    })
    return items
  }

  return {
    MODULE_NAME,
    quoteStatusItemsMerge,
  }
}
