<template>
  <div
    class="bg-white mb-1 w-100"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body mt-1">
      {{ $t('Signature') }}
    </h3>
    <div class="d-flex flex-column gap12">
      <span>{{ $t('By signing, you agree to the terms and conditions in (sample page link).') }}</span>
      <span>{{ $t('Please confirm your name, initials and signature') }}</span>
    </div>
    <div class="d-flex gap12">
      <div class="fullNameBox">
        <h6 class="font-weight-bolder text-body mt-1 pl-1">
          {{ $t('Full Name') }}
        </h6>
        <div class="nameBox">
          <span>{{ dispatchData.billing_contact.fullName }}</span>
        </div>
      </div>
      <div class="initialBox">
        <h6 class="font-weight-bolder text-body mt-1 pl-1">
          {{ $t('Initials') }}
        </h6>
        <div class="nameBox">
          <span>{{ $t('J. D.') }}</span>
        </div>
      </div>
    </div>
    <div class="imageBox">
      <div class="imageWrapper">
        <img
          :src="imageUrl"
          alt="signature"
          width="200"
        >
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/views/main/warehouse/view/dispatch/dispatch-signature/signatureConfig'

export default {
  name: 'SignatureImage',
  computed: {
    dispatchData() {
      return this.$store.state[this.MODULE_NAME].dispatchData
    },
    imageUrl() {
      return this.dispatchData.signature
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return {
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

.gap12{
  gap: 12px;
}

.imageBox{
  margin: 12px 0 ;
  border: 1px solid rgba(221, 227, 231, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
}

.imageWrapper{
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  padding: 12px;
  width: 300px;
  text-align: center;
}

.nameBox{
  padding: 8px 7px;
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px ;
}

.fullNameBox{
  width: 80%;
}
.initialBox{
  width: 20%;
}

</style>
